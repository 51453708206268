import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Route from "@/plugins/wrap/route";

const routes: Array<RouteRecordRaw> = [
  // redirect
  { path: "/", redirect: "/home" },

  // require auth
  Route("home", "/home", () => import("@/screens/home.vue"), true),
  Route("game", "/game/:gameId", () => import("@/screens/home-game.vue"), true),

  //auth
  Route("login", "/login", () => import("@/screens/login.vue")),
  Route(
    "registration",
    "/registration",
    () => import("@/screens/registration.vue")
  ),
];

console.log("fd");

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
