import { App } 		from 'vue'
import { Options } 	from './types'

import axios	 	from '@/plugins/wrap/axios'
import auth			from '@/plugins/wrap/auth'

export default {
	install: (app: App, options: Options = {}) => {
		axios.install(app, options.axios)
		auth.install(app, options.auth)
	}
}