import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import w from "@/plugins/wrap/main";

import "@/styles/global.css";
import "@/styles/tile.css";
import "@/styles/ui.css";

createApp(App).use(store).use(router).use(w).mount("#app");
